import Heading from "./Heading";
import { useMemo, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import format from "date-fns/format";
import ID from "date-fns/locale/id";

import { ReactComponent as Logo } from "assets/images/logo.svg";
import { ReactComponent as LogoChristmas } from "assets/images/mejadokter-natal.svg";
import { ReactComponent as LogoIdulFitri } from "assets/images/mejadokter-idulfitri.svg";
import { ReactComponent as LogoNewYear } from "assets/images/mejadokter-newyear.svg";
import { ReactComponent as LogoHutRI } from "assets/images/mejadokter-merah-putih.svg";

import ListMenu, { TMenu } from "components/molecules/ListMenu";

import Button from "react-components/dist/components/Button";
import { useAppSelector, useAppDispatch } from "lib/store/hooks";
import { toggleSidebar, forceHideSidebar } from "lib/store/slices/components";

import modal from "react-components/dist/components/Modal";
import ModalProfile from "../ModalProfile";
import {
  ArrowLeft,
  CalendarBlank,
  ClockCounterClockwise,
  // Crown,
  FileXls,
  Gear,
  Heart,
  List,
  Plug,
  SealCheck,
  ShoppingCartSimple,
  SquaresFour,
  Stack,
  // Swap,
  User,
} from "@phosphor-icons/react";
import Notifikasi from "../Notifikasi";

import packageJson from "../../../../package.json";
import useMediaQuery from "lib/helpers/useMediaQuery";
import Spinner from "react-components/dist/components/Spinner";

import { useFlag, useVariant } from "@unleash/proxy-client-react";
// import HelpButton from "./HelpButton";
import useQueryParams from "lib/helpers/useQueryParams";

import ContextMenuLockMainMenu from "./ContextMenuLockMainMenu";
import Kiosk from "./Kiosk";
import Pill from "react-components/dist/components/Pill";
import KnownYourCustomer from "./KnownYourCustomer";
import Bpjs from "./Bpjs";

// interface Props {}

const useMenus = () => {
  const { selected } = useAppSelector((state) => state.clinics);
  const users = useAppSelector((state) => state.users);
  const services = useAppSelector((state) => state.services);
  const calendars = useAppSelector((state) => state.calendars);

  const query = useQueryParams();

  const poliAvailables = useMemo(() => {
    const poli: {
      [key: string]: TMenu;
    } = {};

    const queryParamsPoli = `&dateEnd=${query.dateEnd || ""}&dateStart=${
      query.dateStart || ""
    }&status=${query.status || ""}`;

    for (const iterator of selected?.poli || []) {
      if (!iterator.isAntrian) continue;

      if (
        Object.hasOwn(
          users.profile?.authority?.service?.["medical-record"] || {},
          `poli-${iterator._id}`
        ) &&
        !users.profile?.authority?.service?.["medical-record"]?.[
          `poli-${iterator._id}`
        ]
      )
        continue;

      poli[iterator._id] = {
        slug: `/clinics/${selected?._id}/services/rawat-jalan?poli=${iterator._id}&${queryParamsPoli}`,
        searchQueryKey: "poli",
        onClick: () => {
          localStorage.setItem("pelayanan-rajal-poli-remember", iterator._id);
        },
        badge: services.states["rawat-jalan"]?.[iterator._id] || 0,
        title: iterator.name,
      };
    }

    return poli;
  }, [
    selected,
    users.profile?.authority?.service,
    services.states,
    query.dateEnd,
    query.dateStart,
    query.status,
  ]);

  const crmConnectedMenu =
    !!selected &&
    !!Object.hasOwn(selected, "metaDataCrm") &&
    !!selected?.metaDataCrm?.crmEnabled
      ? {
          overview: {
            title: "Ringkasan",
            slug: `/clinics/${selected?._id}/integrations/crm/overview`,
            end: true,
          },
          logs: {
            title: "Riwayat",
            slug: `/clinics/${selected?._id}/integrations/crm/logs`,
            end: true,
          },
          "marketing-campaign": {
            title: "Pemasaran",
            slug: `/clinics/${selected?._id}/integrations/crm/marketing-campaign`,
            end: true,
          },
          communication: {
            title: "Komunikasi",
            slug: `/clinics/${selected?._id}/integrations/crm/communication`,
            end: true,
          },
          // feedback: {
          //   title: "Kritik dan Saran",
          //   slug: `/clinics/${selected?._id}/integrations/crm/feedback`,
          //   end: true,
          // },
          // survey: {
          //   title: (
          //     <span className="inline-flex gap-x-1">
          //       Survei <Crown size={18} className="fill-yellow-700" />
          //     </span>
          //   ),
          //   slug: `/clinics/${selected?._id}/integrations/crm/survey`,
          //   end: true,
          // },
          // chatbot: {
          //   title: (
          //     <span className="inline-flex gap-x-1">
          //       Chatbot <Crown size={18} className="fill-yellow-700" />
          //     </span>
          //   ),
          //   slug: `/clinics/${selected?._id}/integrations/crm/chatbot`,
          //   end: true,
          // },
          // loyalty: {
          //   title: (
          //     <span className="inline-flex gap-x-1">
          //       Poin loyalti <Crown size={18} className="fill-yellow-700" />
          //     </span>
          //   ),
          //   slug: `/clinics/${selected?._id}/integrations/crm/loyalty`,
          //   end: true,
          // },
          // omnichannel: {
          //   title: (
          //     <span className="inline-flex gap-x-1">
          //       Omnichannel <Crown size={18} className="fill-yellow-700" />
          //     </span>
          //   ),
          //   slug: `/clinics/${selected?._id}/integrations/crm/omnichannel`,
          //   end: true,
          // },
          // segmentation: {
          //   title: (
          //     <span className="inline-flex gap-x-1">
          //       Segmentasi
          //       <Crown size={18} className="fill-yellow-700" />
          //     </span>
          //   ),
          //   slug: `/clinics/${selected?._id}/integrations/crm/segmentation`,
          //   end: true,
          // },
        }
      : undefined;

  const bpjsConnectedMenu =
    !!selected &&
    !!Object.hasOwn(selected, "metaData") &&
    !!selected?.metaData?.bpjs
      ? {
          "antrean-online": {
            title: "Antrean Online",
            slug: `/clinics/${selected?._id}/integrations/bpjs/antrean-online`,
            isAnchor: true,
            child: {
              settings: {
                title: "Pengaturan",
                slug: `/clinics/${selected?._id}/integrations/bpjs/antrean-online/settings`,
                end: true,
              },
              // encounter: {
              //   title: "Kunjungan",
              //   slug: `/clinics/${selected?._id}/integrations/bpjs/pcare/encounter`,
              //   end: true,
              // },
            },
          },
          pcare: {
            title: "PCARE",
            slug: `/clinics/${selected?._id}/integrations/bpjs/pcare`,
            isAnchor: true,
            child: {
              settings: {
                title: "Pengaturan",
                slug: `/clinics/${selected?._id}/integrations/bpjs/pcare/settings`,
                end: true,
              },
              encounter: {
                title: "Kunjungan",
                slug: `/clinics/${selected?._id}/integrations/bpjs/pcare/encounter`,
                end: true,
              },
            },
          },
          vclaim: {
            title: "VCLAIM",
            slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim`,
            isAnchor: true,
            child: {
              settings: {
                title: "Pengaturan",
                slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim/settings`,
                end: true,
              },
              sep: {
                title: "SEP",
                slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim/sep`,
                end: true,
              },
              reference: {
                title: "Rujukan",
                slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim/reference`,
                end: true,
              },
              "reference-exclusive": {
                title: "Rujukan Khusus",
                slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim/reference-exclusive`,
                end: true,
              },
              "rencana-kontrol": {
                title: "Rencana Kontrol",
                slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim/rencana-kontrol`,
                end: true,
              },
              prb: {
                title: "Rujuk Balik",
                slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim/prb`,
                end: true,
              },
              lpk: {
                title: "Lembar Pengajuan Klaim",
                slug: `/clinics/${selected?._id}/integrations/bpjs/vclaim/lpk`,
                end: true,
                isMarquee: true,
              },
            },
          },
        }
      : undefined;

  const menus: {
    [key: string]: TMenu;
  } = {
    dashboard: {
      title: "Dashboard",
      icon: <SquaresFour size={22} />,
      slug: `/clinics/${selected?._id}/dashboard`,
    },
    calendars: {
      // isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
      hasAccess:
        users.profile?.entity === "owner" ||
        !!users.profile?.authority?.appointment,
      title: "Kalender",
      icon: (
        <span className="relative">
          <CalendarBlank size={22} />
          <span className="absolute inset-0 flex w-full items-center justify-center pt-1 text-[8px] font-bold">
            {new Date().getDate()}
          </span>
          <span className="absolute -bottom-[14px] flex w-full items-center justify-center pt-1 text-[9px] font-bold tracking-wider">
            {format(new Date(), "MMM", { locale: ID })}
          </span>
        </span>
      ),
      slug: `/clinics/${selected?._id}/calendars`,
      badge: calendars.count,
    },
    services: {
      hasAccess:
        users.profile?.entity === "owner" ||
        [
          users.profile?.authority?.service?.["list-patient"],
          users.profile?.authority?.service?.["nursing-care"],
          !!users.profile?.authority?.service?.["medical-record"],
          !!users.profile?.authority?.service?.["medical-record"]?.treatment,
          !!users.profile?.authority?.service?.["medical-record"]?.history,
          users.profile?.authority?.service?.["laboratorium"],
          users.profile?.authority?.service?.["pharmacy-transactions"],
        ].some((item) => item),
      title: "Pelayanan",
      icon: <Heart size={22} />,
      slug: `/clinics/${selected?._id}/services`,
      hasPulse: services.states["rawat-jalan"].all > 0,
      isAnchor: true,
      child: {
        "rawat-jalan": {
          hasAccess:
            users.profile?.entity === "owner" ||
            [
              users.profile?.authority?.service?.["list-patient"],
              users.profile?.authority?.service?.["nursing-care"],
              !!users.profile?.authority?.service?.["medical-record"],
              !!users.profile?.authority?.service?.["medical-record"]
                ?.treatment,
              !!users.profile?.authority?.service?.["medical-record"]?.history,
            ].some((item) => item),
          title: "Rawat Jalan",
          slug: `/clinics/${selected?._id}/services/rawat-jalan`,
          onClick: () => {
            localStorage.removeItem("pelayanan-rajal-poli-remember");
          },
          badge: services.states["rawat-jalan"].all,
          child: poliAvailables,
        },
        "rawat-inap": {
          hasAccess:
            !!selected?.hasRanap &&
            (users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.service?.["list-patient"],
                users.profile?.authority?.service?.["nursing-care"],
                !!users.profile?.authority?.service?.["medical-record"],
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.treatment,
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.history,
              ].some((item) => item)),
          // isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
          title: "Rawat Inap",
          slug: `/clinics/${selected?._id}/services/rawat-inap`,
          badge: services.states["rawat-inap"],
        },
        laboratorium: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.service?.["laboratorium"]].some(
              (item) => item
            ),
          title: "Laboratorium",
          slug: `/clinics/${selected?._id}/services/laboratorium`,
          badge: services.states["laboratorium"],
        },
        oncall: {
          hasAccess:
            !!selected?.metaData?.oncall?.isActive &&
            (users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.service?.["list-patient"],
                users.profile?.authority?.service?.["nursing-care"],
                !!users.profile?.authority?.service?.["medical-record"],
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.treatment,
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.history,
              ].some((item) => item)),
          // isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
          title: "On Call",
          slug: `/clinics/${selected?._id}/services/oncall`,
          badge: services.states.oncall,
        },
        ugd: {
          hasAccess:
            !!selected?.metaData?.ugd?.isActive &&
            (users.profile?.entity === "owner" ||
              [
                users.profile?.authority?.service?.["list-patient"],
                users.profile?.authority?.service?.["nursing-care"],
                !!users.profile?.authority?.service?.["medical-record"],
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.treatment,
                !!users.profile?.authority?.service?.["medical-record"]
                  ?.history,
              ].some((item) => item)),
          // isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
          title: "UGD",
          slug: `/clinics/${selected?._id}/services/ugd`,
          badge: services.states["ugd"],
        },
        radiologi: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.service?.["radiologi"]].some(
              (item) => item
            ),
          isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
          title: "Radiologi",
          slug: `/clinics/${selected?._id}/services/radiologi`,
          badge: services.states["radiologi"],
        },
        "instalasi-farmasi": {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.service?.["pharmacy-transactions"]].some(
              (item) => item
            ),
          title: "Instalasi Farmasi",
          slug: `/clinics/${selected?._id}/services/instalasi-farmasi`,
          badge: services.states["instalasi-farmasi"],
        },
      },
    },
    pos: {
      hasPulse: services.states["waiting"] > 0,
      hasAccess:
        users.profile?.entity === "owner" ||
        [
          users.profile?.authority?.pos?.create,
          users.profile?.authority?.pos?.logs,
        ].some((item) => item),
      title: "Point of Sales",
      icon: <ShoppingCartSimple size={22} />,
      slug: `/clinics/${selected?._id}/pos`,
      isAnchor: true,
      child: {
        transactions: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.pos?.create].some((item) => item),
          title: "Kasir",
          slug: `/clinics/${selected?._id}/pos/transactions`,
        },
        waiting: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.pos?.create].some((item) => item),
          title: "Menunggu Pembayaran",
          slug: `/clinics/${selected?._id}/pos/waiting`,
          badge: services.states["waiting"],
        },
        logs: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.pos?.logs].some((item) => item),
          title: "Riwayat",
          slug: `/clinics/${selected?._id}/pos/logs`,
        },
      },
    },
    manages: {
      hasAccess:
        users.profile?.entity === "owner" ||
        [
          users.profile?.authority?.manage?.doctor,
          users.profile?.authority?.manage?.nurse,
          users.profile?.authority?.manage?.staff,
          users.profile?.authority?.manage?.patient,
          users.profile?.authority?.manage?.iks,
          // users.profile?.authority?.manage?.["rumah-sakit"],
          users.profile?.authority?.manage?.["polies"],
          users.profile?.authority?.manage?.products,
          users.profile?.authority?.manage?.units,
          users.profile?.authority?.manage?.["product-categories"],
          users.profile?.authority?.manage?.["documents"],
        ].some((item) => item),
      title: "Manajemen Data",
      icon: <Stack size={22} />,
      slug: `/clinics/${selected?._id}/manages`,
      isAnchor: true,
      child: {
        employees: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [
              users.profile?.authority?.manage?.doctor,
              users.profile?.authority?.manage?.nurse,
              users.profile?.authority?.manage?.staff,
            ].some((item) => item),
          title: "Kepegawaian",
          slug: `/clinics/${selected?._id}/manages/employees`,
        },
        patients: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.manage?.patient].some((item) => item),
          title: "Pasien",
          slug: `/clinics/${selected?._id}/manages/patients`,
        },
        iks: {
          hasAccess:
            users.profile?.entity === "owner" ||
            !!users.profile?.authority?.manage?.iks,
          title: "IKS",
          slug: `/clinics/${selected?._id}/manages/iks`,
        },
        // hospitals: {
        //   hasAccess:
        //     users.profile?.entity === "owner" ||
        //     [users.profile?.authority?.manage?.["rumah-sakit"]].some(
        //       (item) => item
        //     ),
        //   hrefOldApp: `${
        //     process.env.REACT_APP_HOST_OLD || "https://app.mejadokter.com"
        //   }/login?root=1&token=${users.token}&path=/manage/rumah-sakit`,
        //   title: "Rumah Sakit/FKTL",
        //   slug: `/clinics/${selected?._id}/manages/hospitals`,
        // },
        polies: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.manage?.["polies"]].some((item) => item),
          title: "Poli",
          slug: `/clinics/${selected?._id}/manages/polies`,
        },
        inpatients: {
          hasAccess:
            !!selected?.hasRanap &&
            (users.profile?.entity === "owner" ||
              [users.profile?.authority?.manage?.inpatients].some(
                (item) => item
              )),
          title: "Ruang Inap",
          slug: `/clinics/${selected?._id}/manages/inpatients`,
        },
        products: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.manage?.products].some((item) => item),
          // hrefOldApp: `${
          //   process.env.REACT_APP_HOST_OLD || "https://app.mejadokter.com"
          // }/login?root=1&token=${users.token}&path=/manage/products`,
          title: "Produk",
          slug: `/clinics/${selected?._id}/manages/products`,
          // child: {
          //   "purchase-order": {
          //     title: "Purchase order",
          //     slug: `/clinics/${selected?._id}/manages/products/purchase-order`,
          //   },
          // },
        },
        suppliers: {
          hasAccess:
            users.profile?.entity === "owner" ||
            [users.profile?.authority?.manage?.suppliers].some((item) => item),
          // hrefOldApp: `${
          //   process.env.REACT_APP_HOST_OLD || "https://app.mejadokter.com"
          // }/login?root=1&token=${users.token}&path=/manage/products`,
          title: "Suplier",
          slug: `/clinics/${selected?._id}/manages/suppliers`,
          // child: {
          //   "purchase-order": {
          //     title: "Purchase order",
          //     slug: `/clinics/${selected?._id}/manages/suppliers/purchase-order`,
          //   },
          // },
        },

        documents: {
          title: "Dokumen",
          slug: `/clinics/${selected?._id}/manages/documents`,
        },
        // diagnoses: {
        //   isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
        //   title: "Diagnosa",
        //   slug: `/clinics/${selected?._id}/manages/diagnoses`,
        // },
      },
    },
    reports: {
      hasAccess:
        users.profile?.entity === "owner" ||
        [
          users.profile?.authority?.reports?.apotek,
          users.profile?.authority?.reports?.services,
          users.profile?.authority?.reports?.sales,
          users.profile?.authority?.reports?.administrasi,
          users.profile?.authority?.reports?.tindakan,
        ].some((item) => item),
      title: "Laporan",
      icon: <FileXls size={22} />,
      slug: `/clinics/${selected?._id}/reports`,
      isAnchor: true,
      child: {
        services: {
          hasAccess:
            users.profile?.entity === "owner" ||
            !!users.profile?.authority?.reports?.["services"],
          title: "Pelayanan",
          slug: `/clinics/${selected?._id}/reports/services`,
          isAnchor: true,
          child: {
            "rawat-jalan": {
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.services ||
                users.profile?.authority?.reports?.services?.["rawat-jalan"],
              title: "Rawat Jalan",
              slug: `/clinics/${selected?._id}/reports/services/rawat-jalan`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/services/rawat-jalan/overview`,
                },
                kunjungan: {
                  title: "Kunjungan",
                  slug: `/clinics/${selected?._id}/reports/services/rawat-jalan/kunjungan`,
                },
              },
            },
            "rawat-inap": {
              isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.services ||
                users.profile?.authority?.reports?.services?.["rawat-inap"],
              title: "Rawat Inap",
              slug: `/clinics/${selected?._id}/reports/services/rawat-inap`,
            },
            ugd: {
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.services ||
                users.profile?.authority?.reports?.services?.["ugd"],
              title: "UGD",
              slug: `/clinics/${selected?._id}/reports/services/ugd`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/services/ugd/overview`,
                },
                kunjungan: {
                  title: "Kunjungan",
                  slug: `/clinics/${selected?._id}/reports/services/ugd/kunjungan`,
                },
              },
            },
            oncall: {
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.services ||
                users.profile?.authority?.reports?.services?.["oncall"],
              title: "On Call",
              slug: `/clinics/${selected?._id}/reports/services/oncall`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/services/oncall/overview`,
                },
                kunjungan: {
                  title: "Kunjungan",
                  slug: `/clinics/${selected?._id}/reports/services/oncall/kunjungan`,
                },
              },
            },
            laboratorium: {
              isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.services ||
                users.profile?.authority?.reports?.services?.["laboratorium"],
              title: "Laboratorium",
              slug: `/clinics/${selected?._id}/reports/services/laboratorium`,
            },
            radiologi: {
              isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.services ||
                users.profile?.authority?.reports?.services?.["radiologi"],
              title: "Radiologi",
              slug: `/clinics/${selected?._id}/reports/services/radiologi`,
            },
          },
        },
        sales: {
          hasAccess:
            users.profile?.entity === "owner" ||
            !!users.profile?.authority?.reports?.sales,
          title: "Penjualan",
          slug: `/clinics/${selected?._id}/reports/sales`,
          child: {
            "rawat-jalan": {
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.sales ||
                users.profile?.authority?.reports?.sales?.["rawat-jalan"],
              title: "Rawat Jalan",
              slug: `/clinics/${selected?._id}/reports/sales/rawat-jalan`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/sales/rawat-jalan/overview`,
                },
                details: {
                  title: "Terinci",
                  slug: `/clinics/${selected?._id}/reports/sales/rawat-jalan/details`,
                },
                // tindakan: {
                //   title: "Tindakan",
                //   slug: `/clinics/${selected?._id}/reports/sales/rawat-jalan/tindakan`,
                // },
              },
            },
            "rawat-inap": {
              // isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.sales ||
                users.profile?.authority?.reports?.sales?.["rawat-inap"],
              title: "Rawat Inap",
              slug: `/clinics/${selected?._id}/reports/sales/rawat-inap`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/sales/rawat-inap/overview`,
                },
                details: {
                  title: "Terinci",
                  slug: `/clinics/${selected?._id}/reports/sales/rawat-inap/details`,
                },
                // tindakan: {
                //   title: "Tindakan",
                //   slug: `/clinics/${selected?._id}/reports/sales/rawat-jalan/tindakan`,
                // },
              },
            },
            ugd: {
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.sales ||
                users.profile?.authority?.reports?.sales?.["ugd"],
              title: "UGD",
              slug: `/clinics/${selected?._id}/reports/sales/ugd`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/sales/ugd/overview`,
                },
                details: {
                  title: "Terinci",
                  slug: `/clinics/${selected?._id}/reports/sales/ugd/details`,
                },
              },
            },
            oncall: {
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.sales ||
                users.profile?.authority?.reports?.sales?.["oncall"],
              title: "On Call",
              slug: `/clinics/${selected?._id}/reports/sales/oncall`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/sales/oncall/overview`,
                },
                details: {
                  title: "Terinci",
                  slug: `/clinics/${selected?._id}/reports/sales/oncall/details`,
                },
              },
            },
            laboratorium: {
              isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.sales ||
                users.profile?.authority?.reports?.sales?.["laboratorium"],
              title: "Laboratorium",
              slug: `/clinics/${selected?._id}/reports/sales/laboratorium`,
            },
            radiologi: {
              isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.sales ||
                users.profile?.authority?.reports?.sales?.["radiologi"],
              title: "Radiologi",
              slug: `/clinics/${selected?._id}/reports/sales/radiologi`,
            },
            pos: {
              hasAccess:
                users.profile?.entity === "owner" ||
                users.profile?.authority?.reports?.sales,
              title: "Penjualan Langsung",
              slug: `/clinics/${selected?._id}/reports/sales/pos`,
              isAnchor: true,
              child: {
                overview: {
                  title: "Ringkasan",
                  slug: `/clinics/${selected?._id}/reports/sales/pos/overview`,
                },
                details: {
                  title: "Terinci",
                  slug: `/clinics/${selected?._id}/reports/sales/pos/details`,
                },
              },
            },
          },
        },
        administrasi: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.reports?.administrasi,
          title: "Penerbitan Surat",
          slug: `/clinics/${selected?._id}/reports/administrasi`,
        },
        "bagi-hasil-tindakan": {
          hasAccess:
            users.profile?.entity === "owner" ||
            !!users.profile?.authority?.reports?.["tindakan"],
          title: "Bagi Hasil",
          slug: `/clinics/${selected?._id}/reports/bagi-hasil-tindakan`,
        },
        apotek: {
          hasAccess:
            users.profile?.entity === "owner" ||
            !!users.profile?.authority?.reports?.apotek,
          title: "Apotek",
          slug: `/clinics/${selected?._id}/reports/apotek`,
          child: {
            overview: {
              title: "Ringkasan",
              slug: `/clinics/${selected?._id}/reports/apotek/overview`,
            },
            logs: {
              title: "Riwayat",
              slug: `/clinics/${selected?._id}/reports/apotek/logs`,
            },
            "drugs-condition": {
              title: "Kondisi Stok",
              slug: `/clinics/${selected?._id}/reports/apotek/drugs-condition`,
            },
            puyer: {
              title: "Puyer",
              slug: `/clinics/${selected?._id}/reports/apotek/puyer`,
            },
          },
        },
      },
    },
    settings: {
      hasAccess:
        users.profile?.entity === "owner" ||
        [
          users.profile?.authority?.settings?.general,
          users.profile?.authority?.settings?.logo,
          users.profile?.authority?.settings?.bpjs,
          users.profile?.authority?.settings?.["rawat-inap"],
          users.profile?.authority?.settings?.["rawat-jalan"],
          users.profile?.authority?.settings?.["hak-kewajiban-pasien"],
          users.profile?.authority?.settings?.["instalasi-farmasi"],
          users.profile?.authority?.settings?.["coding-rm"],
          users.profile?.authority?.settings?.["point-of-sales"],
          users.profile?.authority?.settings?.["calendars"],
          users.profile?.authority?.settings?.["kiosk"],
          users.profile?.authority?.settings?.["announcers"],
          users.profile?.authority?.settings?.["kegawat-daruratan"],
          users.profile?.authority?.settings?.["oncall"],
          users.profile?.authority?.settings?.["pemeriksaan"],
          // users.profile?.authority?.settings?.["notifications"],
        ].some((item) => item),
      title: "Pengaturan",
      icon: <Gear size={22} />,
      slug: `/clinics/${selected?._id}/settings`,
      isAnchor: true,
      child: {
        general: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.general,
          title: "Informasi Umum",
          slug: `/clinics/${selected?._id}/settings/general`,
          end: true,
        },
        logo: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.logo,
          title: "Logo",
          slug: `/clinics/${selected?._id}/settings/logo`,
          end: true,
        },
        "instalasi-farmasi": {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["instalasi-farmasi"],
          title: "Instalasi Farmasi",
          slug: `/clinics/${selected?._id}/settings/instalasi-farmasi`,
          end: true,
        },
        "coding-rm": {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["coding-rm"],
          title: "Koding RM",
          slug: `/clinics/${selected?._id}/settings/coding-rm`,
          end: true,
        },
        "point-of-sales": {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["point-of-sales"],
          title: "Point-of-sales",
          slug: `/clinics/${selected?._id}/settings/point-of-sales`,
          end: true,
        },
        bpjs: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.bpjs,
          title: "BPJS",
          slug: `/clinics/${selected?._id}/integrations/bpjs/pcare/settings`,
          end: true,
        },
        "rawat-inap": {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["rawat-inap"],
          title: "Rawat Inap",
          slug: `/clinics/${selected?._id}/settings/rawat-inap`,
          end: true,
        },
        // "rawat-jalan": {
        //   hasAccess:
        //     users.profile?.entity === "owner" ||
        //     users.profile?.authority?.settings?.["rawat-jalan"],
        //   title: "Rawat Jalan",
        //   slug: `/clinics/${selected?._id}/settings/rawat-jalan`,
        //   end: true,
        // },
        "hak-kewajiban-pasien": {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["hak-kewajiban-pasien"],
          title: "Hak & Kewajiban Pasien",
          slug: `/clinics/${selected?._id}/settings/hak-kewajiban-pasien`,
          end: true,
        },
        calendars: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["calendars"],
          title: "Kalender",
          slug: `/clinics/${selected?._id}/settings/calendars`,
          end: true,
        },
        kiosk: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["kiosk"],
          title: "Kiosk",
          slug: `/clinics/${selected?._id}/settings/kiosk`,
          end: true,
        },
        announcers: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["announcers"],
          title: "Announcers",
          slug: `/clinics/${selected?._id}/settings/announcers`,
          end: true,
        },
        ugd: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["ugd"],
          title: "UGD",
          slug: `/clinics/${selected?._id}/settings/ugd`,
          end: true,
        },
        oncall: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["oncall"],
          title: "On Call",
          slug: `/clinics/${selected?._id}/settings/oncall`,
          end: true,
        },
        pemeriksaan: {
          hasAccess:
            users.profile?.entity === "owner" ||
            users.profile?.authority?.settings?.["pemeriksaan"],
          title: "Pemeriksaan",
          slug: `/clinics/${selected?._id}/settings/pemeriksaan`,
          end: true,
        },
      },
    },
    integrations: {
      isAnchor: true,
      title: "Modul & Integrasi",
      icon: <Plug size={22} />,
      slug: `/clinics/${selected?._id}/integrations`,
      child: {
        bpjs: {
          // hasAccess:
          //   users.profile?.entity === "owner" ||
          //   users.profile?.authority?.integrations?.bpjs,
          title: "BPJS",
          slug: `/clinics/${selected?._id}/integrations/bpjs`,
          child: bpjsConnectedMenu,
          isAnchor: true,
        },
        crm: {
          // hasAccess:
          //   users.profile?.entity === "owner" ||
          //   users.profile?.authority?.integrations?.whatsapp,
          // isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
          title: (
            <span className="relative flex flex-col items-start">
              <span className="">Hubungan Pelanggan</span>
              <Pill size="xs" state="info">
                CRM
              </Pill>
            </span>
          ),
          slug: `/clinics/${selected?._id}/integrations/crm`,
          child: crmConnectedMenu,
        },
        "landing-page": {
          // hasAccess:
          //   users.profile?.entity === "owner" ||
          //   users.profile?.authority?.integrations?.["landing-page"],
          title: "Landing Page",
          slug: `/clinics/${selected?._id}/integrations/landing-page`,
        },
        satusehat: {
          // hasAccess:
          //   users.profile?.entity === "owner" ||
          //   users.profile?.authority?.integrations?.["landing-page"],
          // isUnderconstruction: process.env.REACT_APP_STAGING !== "dev",
          title: (
            <span className="relative flex flex-col items-start">
              <span className="">Satu Sehat</span>
              <Pill size="xs" state="info">
                by Kemenkes
              </Pill>
            </span>
          ),
          slug: `/clinics/${selected?._id}/integrations/satusehat`,
        },
      },
    },
    histories: {
      hasAccess:
        users.profile?.entity === "owner" ||
        [users.profile?.authority?.histories].some((item) => item),
      end: true,
      title: "Riwayat Penggunaan",
      icon: <ClockCounterClockwise size={22} />,
      slug: `/clinics/${selected?._id}/histories`,
    },
  };

  return menus;
};

function ButtonLogoMejadokter() {
  const isSpecialEvent = useFlag("special_event");
  const variant = JSON.parse(
    useVariant("special_event")?.payload?.value || "{}"
  );

  return (
    <div className="relative mt-1 text-center flex flex-col mb-1">
      <div className="group relative">
        <Button
          ripple
          className={
            isSpecialEvent
              ? "flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border-2 border-blue-800 cursor-pointer"
              : "flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-yellow-700 cursor-pointer"
          }
          state="custom"
        >
          <Link to={`/about`}>
            {isSpecialEvent ? (
              <>
                {variant?.key === "christmas" && <LogoChristmas />}

                {variant?.key === "newyear" && <LogoNewYear />}

                {variant?.key === "hutri" && <LogoHutRI />}

                {variant?.key === "idulfitri" && <LogoIdulFitri />}
              </>
            ) : (
              <Logo width={22} className="logo-white" />
            )}
          </Link>
        </Button>
        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
          Tentang Mejadokter
        </span>
      </div>

      <span className="pointer-events-none text-xxs text-white">
        v{packageJson.version}
      </span>
    </div>
  );
}

const Sidebar = () => {
  const [isLoadingSelectingClinic, isLoadingSelectingClinicSet] =
    useState(false);

  const calendars = useAppSelector((state) => state.calendars);
  const { sidebar } = useAppSelector((state) => state.components);
  const { selected } = useAppSelector((state) => state.clinics);
  const users = useAppSelector((state) => state.users);
  const services = useAppSelector((state) => state.services);

  const isMobile = useMediaQuery("(max-width: 767px)");

  const dispatch = useAppDispatch();

  const location = useLocation();

  const menus = useMenus();

  function fnModalProfile() {
    modal.show(
      <ModalProfile
        isLoadingSelectingClinicSet={isLoadingSelectingClinicSet}
      />,
      {
        close: {
          outsideClick: true,
          closeOnEscape: true,
          display: false,
        },
        container: {
          className: "flex justify-start min-h-screen",
        },
        overlay: {
          overrideClassName: "overflow-x-hidden",
        },
        wrapper: {
          className:
            "min-h-screen bg-white dark:bg-gray-900 h-full w-[320px] relative bg-white transition-all duration-300",
          timeout: 300,
          animatedClassNames: {
            appear: "-left-full opacity-0",
            appearActive: "-left-full opacity-0",
            appearDone: "opacity-100 left-0",
            enter: "-left-full opacity-100",
            enterActive: "-left-full opacity-100",
            enterDone: "left-0 opacity-100",
            exit: "left-0",
            exitActive: "-left-full opacity-0",
            exitDone: "-left-full opacity-0",
          },
        },
      }
    );
  }

  function onContextMenuLockSidebar(e: any) {
    e.preventDefault();

    modal.show(<ContextMenuLockMainMenu />, {
      close: {
        outsideClick: true,
        closeOnEscape: true,
        display: false,
      },
      container: {
        className: "",
      },
      overlay: {
        timeout: 0,
        // className: "absolute top-0 left-0",
        // overrideClassName: "bg-black/0",
        animatedClassNames: {
          appear: "opacity-0 bg-black/0",
          appearActive: "opacity-0 bg-black/0",
          appearDone: "opacity-100 bg-black/0",
          enter: "opacity-0 bg-black/0",
          enterActive: "opacity-0 bg-black/0",
          enterDone: "opacity-100 bg-black/0",
          exit: "",
          exitActive: "opacity-0 bg-black/0",
          exitDone: "opacity-0 bg-black/0",
        },
      },
      wrapper: {
        className:
          "bg-white dark:bg-gray-900 w-[220px] relative bg-white shadow-lg rounded",
        timeout: 0,
        style: {
          left: e.clientX,
          top: e.clientY,
        },
      },
    });
  }

  // const blackListToggleMenu = [
  //   "services/rekam-medis/",
  //   "services/laboratorium/",
  // ];

  return (
    <>
      {isLoadingSelectingClinic && (
        <div className="fixed inset-0 z-[999] flex items-center justify-center gap-x-4 bg-white/95 backdrop-blur-sm">
          <Spinner size="lg" /> Mempersiapkan faskes...
        </div>
      )}
      <div className="h-full lg:h-auto lg:w-auto lg:flex-auto">
        <div
          className={[
            "fixed top-0 z-50 flex transition-all duration-300",
            sidebar.isActive ? "left-0" : "-left-full lg:left-0",
          ].join(" ")}
        >
          <aside className="relative z-50 flex h-screen w-16 flex-col items-center justify-between shadow-sm dark:shadow-gray-700 bg-blue-900 dark:bg-gray-950">
            {selected && (
              <>
                <div className="group relative mt-3 mb-2 flex">
                  <Button
                    ripple
                    className="w-10 justify-center rounded-full bg-white"
                    state="custom"
                  >
                    <Link to={`/clinics/${selected._id}`}>
                      <img
                        src={selected.logo}
                        className="aspect-square object-cover"
                        alt={selected.name}
                      />
                    </Link>
                  </Button>
                  <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                    {selected.name}{" "}
                    {!!selected.branch &&
                      selected.branch !== "" &&
                      `(${selected.branch})`}
                  </span>
                </div>
              </>
            )}

            {selected && location.pathname.includes("/clinics/") && (
              <div className="flex flex-col gap-y-2 relative z-20 bg-blue-900 dark:bg-gray-950 pb-4">
                {isMobile ? (
                  <div className="group relative">
                    <Button
                      ripple
                      className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
                      state="custom"
                    >
                      <button onClick={() => dispatch(toggleSidebar(false))}>
                        <ArrowLeft size={22} />
                      </button>
                    </Button>
                    <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                      {!sidebar.isForceHidden
                        ? "Sembunyikan Menu"
                        : "Munculkan Menu"}
                    </span>
                  </div>
                ) : (
                  <div className="group relative">
                    <Button
                      ripple
                      className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
                      state="custom"
                    >
                      <button
                        onClick={() =>
                          dispatch(forceHideSidebar(!sidebar.isForceHidden))
                        }
                        onContextMenu={onContextMenuLockSidebar}
                      >
                        <List size={22} />
                      </button>
                    </Button>
                    <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                      {!sidebar.isForceHidden
                        ? "Sembunyikan Menu"
                        : "Munculkan Menu"}
                    </span>
                  </div>
                )}
                {(users.profile?.entity === "owner" ||
                  users.profile?.authority?.appointment) && (
                  <div className="group relative">
                    <Button
                      ripple
                      className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
                      state="custom"
                    >
                      <Link
                        to={`/clinics/${selected?._id}/calendars`}
                        onClick={() => dispatch(toggleSidebar(false))}
                      >
                        <span className="relative">
                          <CalendarBlank size={22} />
                          <span className="absolute inset-0 flex w-full items-center justify-center pt-1 text-[8px] font-bold">
                            {new Date().getDate()}
                          </span>
                          <span className="absolute -bottom-[12px] flex w-full items-center justify-center pt-[2px] text-[7px] font-bold tracking-wider">
                            {format(new Date(), "MMM", { locale: ID })}
                          </span>
                        </span>
                      </Link>
                    </Button>
                    {/* {!location.pathname.includes("/calendars") &&
                      calendars.count > 0 && (
                        <span
                          style={{ right: 2, bottom: 2 }}
                          className="pointer-events-none absolute flex h-2 w-2 items-center justify-center rounded-full border
        border-white bg-yellow-700 text-xs font-bold text-blue-900"
                        ></span>
                      )} */}
                    {calendars.count > 0 && (
                      <span className="pointer-events-none absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full border border-white bg-yellow-700 text-xxs font-bold text-blue-900">
                        {calendars.count}
                      </span>
                    )}
                    {location.pathname.includes("/calendars") &&
                      !(
                        location.pathname.includes("/logs") ||
                        location.pathname.includes("/waiting")
                      ) && (
                        <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
                      )}
                    <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                      Kalender
                    </span>
                  </div>
                )}

                {(users.profile?.entity === "owner" ||
                  [
                    users.profile?.authority?.pos?.create,
                    users.profile?.authority?.pos?.logs,
                  ].some((item) => item)) && (
                  <div
                    className={[
                      "-mx-1 flex flex-col gap-y-2 rounded-full p-1 transition-all duration-300",
                      location.pathname.includes("/pos")
                        ? "bg-blue-700 dark:bg-gray-800"
                        : "group hover:bg-blue-700 hover:dark:bg-gray-800",
                    ].join(" ")}
                  >
                    {(users.profile?.entity === "owner" ||
                      users.profile?.authority?.pos?.create) && (
                      <div className="group relative">
                        <Button
                          ripple
                          className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
                          state="custom"
                        >
                          <Link
                            to={`/clinics/${selected._id}/pos`}
                            onClick={() => dispatch(toggleSidebar(false))}
                          >
                            <ShoppingCartSimple size={22} />
                          </Link>
                        </Button>
                        {!location.pathname.includes("/pos") &&
                          services.states["waiting"] > 0 && (
                            <span
                              style={{ right: 2, bottom: 2 }}
                              className="pointer-events-none absolute flex h-2 w-2 items-center justify-center rounded-full border
        border-white bg-yellow-700 text-xs font-bold text-blue-900"
                            ></span>
                          )}
                        {location.pathname.includes("/pos") &&
                          !(
                            location.pathname.includes("/logs") ||
                            location.pathname.includes("/waiting")
                          ) && (
                            <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
                          )}
                        <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                          Kasir
                        </span>
                      </div>
                    )}

                    <div
                      className={[
                        "group relative transition-all duration-300",
                        (users.profile?.entity === "owner" ||
                          users.profile?.authority?.pos?.create) &&
                        location.pathname.includes("/pos")
                          ? ""
                          : "hidden opacity-0 group-hover:block group-hover:opacity-100",
                      ].join(" ")}
                    >
                      <Button
                        ripple
                        className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
                        state="custom"
                      >
                        <Link
                          to={`/clinics/${selected._id}/pos/waiting`}
                          onClick={() => {
                            dispatch(forceHideSidebar(false));
                            dispatch(toggleSidebar(false));
                          }}
                        >
                          <SealCheck size={22} />
                        </Link>
                      </Button>
                      {services.states["waiting"] > 0 && (
                        <span className="pointer-events-none absolute -bottom-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full border border-white bg-yellow-700 text-xxs font-bold text-blue-900">
                          {services.states["waiting"]}
                        </span>
                      )}
                      {location.pathname.includes("/waiting") && (
                        <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
                      )}
                      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                        Menunggu Pembayaran
                      </span>
                    </div>

                    <div
                      className={[
                        "group relative transition-all duration-300",
                        (users.profile?.entity === "owner" ||
                          users.profile?.authority?.pos?.create) &&
                        location.pathname.includes("/pos")
                          ? ""
                          : "hidden opacity-0 group-hover:block group-hover:opacity-100",
                      ].join(" ")}
                    >
                      <Button
                        ripple
                        className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900"
                        state="custom"
                      >
                        <Link
                          to={`/clinics/${selected._id}/pos/logs`}
                          onClick={() => {
                            dispatch(forceHideSidebar(false));
                            dispatch(toggleSidebar(false));
                          }}
                        >
                          <ClockCounterClockwise size={22} />
                        </Link>
                      </Button>
                      {location.pathname.includes("/logs") && (
                        <span className="absolute -right-4 top-1/2 z-50 h-6 w-1 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-yellow-700"></span>
                      )}
                      <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                        Riwayat Transaksi
                      </span>
                    </div>
                  </div>
                )}
                <Notifikasi />
                <Kiosk />
                <KnownYourCustomer />

                <Bpjs />

                <span className="absolute -bottom-10 w-full h-10 bg-gradient-to-b from-blue-900 dark:from-gray-950 to-transparent"></span>
              </div>
            )}

            <div className="mb-auto"></div>

            <div className="flex flex-col items-center gap-y-2 text-white fixed bottom-0 z-10">
              <div className="group relative">
                <Button
                  ripple
                  className="w-10 justify-center rounded-full bg-blue-800 text-white dark:bg-gray-900 "
                  state="custom"
                >
                  <button id="modal-profile" onClick={fnModalProfile}>
                    <User size={22} />
                  </button>
                </Button>
                <span className="pointer-events-none absolute left-0 top-1/2 z-50 ml-2 flex -translate-y-1/2 transform select-none whitespace-nowrap bg-black/75 px-2 py-1 text-sm text-white opacity-0 transition-all group-hover:left-full group-hover:opacity-100">
                  Profil
                </span>
              </div>

              {/* <HelpButton /> */}

              <ButtonLogoMejadokter />
            </div>
          </aside>

          {selected &&
            location.pathname.includes("/clinics/") &&
            (!sidebar.isForceHidden || isMobile) && (
              <aside className="sidebar relative z-10 flex h-screen w-72 flex-col overflow-y-auto border-r border-gray-200 bg-gray-50 pb-32 shadow-sm shadow-gray-200 dark:shadow-gray-700 dark:border-gray-900 dark:bg-gray-925 dark:text-gray-50 ">
                <Heading />

                <ListMenu menus={menus} className="mt-4 px-4" />
              </aside>
            )}
        </div>

        {sidebar.isActive && (
          <div
            className="fixed inset-0 z-[31]"
            onClick={() => dispatch(toggleSidebar(false))}
          ></div>
        )}
      </div>

      <div className="hidden w-16 flex-none lg:table-cell"></div>
      {selected &&
        location.pathname.includes("/clinics/") &&
        !sidebar.isForceHidden && (
          <div className="hidden w-72 flex-none lg:table-cell"></div>
        )}
    </>
  );
};

export default Sidebar;
