import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import APIClinics from "lib/constants/api/clinics";
import { selectClinic } from "lib/store/slices/clinics";
import { login } from "lib/store/slices/users";
import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

const useSettings = () => {
  const dispatch = useAppDispatch();
  const clinics = useAppSelector((state) => state.clinics);
  const toastId = useRef<any>(null);

  const [formLoading, setFormLoading] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (payload: any, onSuccessMessage: string, onNext?: Function) => {
      if (clinics.selected?._id) {
        try {
          setFormLoading(true);

          await APIClinics.update(payload);

          toastId.current = toast.info("Memperbarui informasi faskes...", {
            autoClose: false,
          });

          const response = await APIClinics.select(clinics.selected?._id);

          dispatch(
            selectClinic({
              ...response.data.clinicDetails,
              poli: response.data.poliHospital,
              doctor: response.data.doctor,
              hospital: response.data.hospital,
              iks: response.data.iks,
              nurse: response.data.nurse,
              diagnosa: response.data.diagnosa,
            })
          );

          dispatch(
            login({
              token: response.data.token,
              tokenRefresh: response.data.tokenRefresh,
            })
          );

          toast.update(toastId.current, {
            render: onSuccessMessage,
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
          });

          setFormLoading(false);

          onNext && onNext();
        } catch (error: any) {
          setFormLoading(false);

          toast.warning(JSON.stringify(error.response.data));
        }
      }
    },
    [clinics.selected?._id, dispatch]
  );

  return { formLoading, onSubmit };
};

export default useSettings;
